import React from 'react';
import "./Ourteam.css";
import { Line } from '../components';

const Ourteam20232024 = () => {
  return (
    <div className="ourteam">

      <div className="ourteam_title_container">
        <div className="ourteam_title">
          our leaders
        </div>
        <div className="ourteam_description">
          As the core of International Buddy, our volunteers make everything we do possible. Below are some of our organizational leaders, who lead our volunteers in everything we do.
        </div>
      </div>
      <div className="ourteam_content_container">
      <div className="ourteam_container1">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2FGavin%20Zhou%20-%20President.jpg?alt=media&token=6ae3e86e-cf39-4f8e-b81e-1ed7a4f1385e" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Gavin Zhou<br />
          President
          </div>
          <div className="ourteam_selfintro">
          Hi, I'm Gavin Zhou, a senior at Issaquah High School. Since joining International Buddy, I've had the incredible opportunity to contribute to our diverse community and meet amazing individuals to build relationships and growth with our buddies. Providing a nurturing environment has always been our goal, and it has been beyond rewarding to be a part of the progress. As the president, I am committed to continuing our improvement by supporting our fundraising, outreach, and organizational efforts.
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container2">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2FHansley%20Wang%20-%20VP%20of%20Community%20Outreach.jpg?alt=media&token=18ce8d58-9c27-4ce5-b619-f49052541e2d" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Hansley Wang <br />
          VP of Community Outreach
          </div>
          <div className="ourteam_selfintro">
          Hi, my name is Hansley Wang, and I go to International School. I am a rising Senior for the 2022 - 2023 school year. I joined International Buddy in 2022, and this organization have allowed me to experience memorable moments and meet amazing people. As the VP of Community Outreach, I am responsible for creating awareness and interest in joining International Buddy, attracting new members to join. I am excited for this role, because I will be able to contribute in building a vibrant community, helping others in the BSD in discovering and joining this organization.
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container1">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2FSteven%20Gao-%20VP%20of%20technology%201.jpg?alt=media&token=13312538-41c0-47db-8482-33e2cdb5079b" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Steven Gao <br />
          VP of Technology
          </div>
          <div className="ourteam_selfintro">
          I'm Steven Gao, a current junior at Mercer Island High School for the 2023-24 school year. Since joining the International Buddy program in my freshmen year, my time here has been nothing but enriching and rewarding. As VP of technology, I plan to help with the technical aspects of maintaining the website and social media while continuing to collaborate with others to provide kids with a fun and unforgettable experience.
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container2">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2FSean%20Choudhury%20-%20VP%20of%20Finance.jpg?alt=media&token=8d5250df-c863-4748-affe-a2cac52e1544" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Sean Choudhury <br />
          VP of Finance
          </div>
          <div className="ourteam_selfintro">
          My name is Sean Choudhury. I am a rising Junior at international school.I have been working with International Buddy for about a year now. I helped the kids as a swimming coach and enjoyed spending time with each kids in the summer camp. I will actively participate or organize in fundraising activities for the organization. I am committed to working alongside with other leaders in the organization to make a difference and ultimately provide a good experience for each kid.
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container1">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2FEli%20Hou%20-%20VP%20of%20Administration.jpg?alt=media&token=6181de7e-461a-4f42-971c-a45a5c50bc2a" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Eli Hou <br />
          VP of Administration
          </div>
          <div className="ourteam_selfintro">
          Hi my name is Eli Hou and I attend International School. I joined international buddy in the summer of 8th grade during the pandemic and have really enjoyed working with the kids. My time at International Buddy has taught me a lot and as the VP of administration, I will be able to help International Buddy flourish. As VP of administration, I will be in charge of creating activities and organizing 1 on 1s for buddies and volunteers. If anyone is interested in 1 on 1s or creating new activities, please contact me.
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container2">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2FKyle%20Li%20-%20chess%20lead.jpg?alt=media&token=5145c6ce-d666-4906-adb3-448afbf07b49" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Kyle Li <br />
          Chess Team Leader
          </div>
          <div className="ourteam_selfintro">
          Hey there, I'm Kyle Li, a senior at International School and the Chess Team Leader at International Buddy. While I may not hold the title of Chess Grandmaster, I have a deep passion for the game and an abundance of knowledge to share. Being able to make a positive impact in the lives of these incredible kids is truly invaluable to me, and I am very grateful for having this opportunity. For me, it's not just about teaching chess; it's also about fostering connections, building confidence, and creating memories that will last a lifetime. Our Chess Club is an inclusive haven where everyone is welcome, and I'm committed to creating even more fun opportunities and learning experiences, both on and off the board.
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container1">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2FEvan_new.jpg?alt=media&token=50f8d81e-c611-41d2-bf5e-6213b4a724c6" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Evan Zheng <br />
          Ambassador
          </div>
          <div className="ourteam_selfintro">
          My name is Evan Zheng, and I am currently a volunteer and the ambassador of the International Buddy Organization. Throughout my part within this organization, I have participated in many social activities with autistic children, such as teaching them how to swim, read, write, etc.  As the ambassador of International Buddy, I am in charge of understanding both the volunteers and the autistic children, and finding ways for them both to understand each other better.
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container2">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2Fivan-lee-2-882x1024.jpg.jpeg?alt=media&token=dd58dd31-4e29-45e6-b6e4-3bb3cde4e8e2" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Ivan Lee <br />
          Basketball Team Leader
          </div>
          <div className="ourteam_selfintro">
          Hi, I'm Ivan Lee and I am currently a junior at International School. I am a volunteer and the lead of the Basketball Team. I have enjoyed taking care of the children ever since joining International Buddy and I think it has always been a great experience for the kids and the volunteers. As the basketball team leader, I hope to keep providing even more fun opportunities and learning experiences on and off the court.
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container1">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2FAndrew%20Kim_soccer%20team%20lead.jpg?alt=media&token=ea6488e1-1d61-42ed-bdd1-fd3a6e29406f" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Andrew Kim <br />
          Soccer Team Leader
          </div>
          <div className="ourteam_selfintro">
          Hi,  I'm Andrew Kim, a junior at Issaquah High School. One thing that brings me a lot of joy is being a sports volunteer for International Buddies. Every week, we get together and play different team sports like basketball, soccer, and baseball. It's rewarding to see the happiness on the kids' faces when we're all having a blast together and providing them opportunities to participate in team sports. I can't wait for our weekly meetups and to continue supporting International Buddies as the Soccer Lead this year.
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container2">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2FMax%20Li%20-%20Bellevue%20Swimming%20Team%20Leader.jpg?alt=media&token=039e5630-04a5-4275-9322-058fa9967063" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Max Li<br />
          Bellevue Swimming Team Leader
          </div>
          <div className="ourteam_selfintro">
          Hello, my name is Max Li and I will be going into my junior year at International School in September 2023.  I have been a part of International Buddy for over a year. I am proud to have the opportunity to serve as the swim team leader. Over the course of six years, I have immersed myself in the world of swimming, honing my skills and pushing my limits in pursuit of excellence. With my skills developed through swimming, I hope that I will be able to have a positive impact on all the children at IB, whether to teach them swimming, or to help them pursue their passion in competitive swimming. Throughout my swimming journey I have instilled a deep sense of determination, discipline, and leadership. I aim to bring those qualities to our swim team.
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container1">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2FArthur%20Gao_Swimming%20team%20lead%20at%20Issaquah_crop_new2.jpg?alt=media&token=cd8d32f8-e653-4253-a292-99646e82983f" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Arthur Gao <br />
          Issaquah Swimming Team Leader
          </div>
          <div className="ourteam_selfintro">
          I'm Arthur Gao, a member of Issaquah High School's class of 2025. Leading swimming instruction for neurodivergent children at Julius Boehm Pool, I offer personalized lessons tailored to their unique needs. It's incredibly rewarding to help them become more comfortable in the water, explaining freestyle form and playing games. Join us at Julius Boehm Pool in Issaquah, where we foster inclusivity, accessibility, and a love for swimming, creating an unforgettable experience of water safety, skill development, and personal growth.
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container2">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2FJulia%20Shang%20-%20Mercer%20Island%20Swimming%20Team%20Leader.jpg?alt=media&token=7edddb84-11a0-4732-a928-9e4d341659b3" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Julia Shang<br />
          Mercer Island Swimming Team Leader
          </div>
          <div className="ourteam_selfintro">
          Hi! I’m Julia, a rising Sophomore at Mercer Island High School. I joined in February of 2023, and have thoroughly enjoyed my time here as a volunteer and am super excited for the future! I’ve loved working with the kids, and every aspect of this program. As team leader, I will be continuing what we have already been doing, as well as finding different ways to improve the program.
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container1">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2FElla%20Chang%20-%20Art%20Team%20Leader.jpg?alt=media&token=bab3aa3e-96a2-4293-abb4-4bb8c99b8d6e" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Ella Chang<br />
          Art Team Leader
          </div>
          <div className="ourteam_selfintro">
          Hi, I'm Ella Chang and I am a rising junior at Newport High School! I joined International Buddy in 2022 and have since started the art group, volunteering as the team leader. Kids with special needs can enhance communication and express themselves freely through drawing, and it has been amazing and rewarding seeing the progress of each kid in my group. I hope to continue providing and supporting them with fun, creative experiences.
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container2">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2FRyan%20Pan%20-%20Outreach%20Leader%20%40%20Issaquah.jpg?alt=media&token=64c6278c-e114-41f9-b738-9a7614aa1078" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Yiran (Ryan) Pang<br />
          Director of Digital Media & Issaquah Outreach
          </div>
          <div className="ourteam_selfintro">
          I'm Yiran Pang and I am a junior at Issaquah High School. Ever since joining International Buddy, I've been proactively contributing my passion and skills. I established a club as the President at my high school to spread awareness and recruit volunteers. Besides weekly volunteering activities, I started a tennis team at IB's regular Sunday events. Furthermore, I'm taking the responsibility of developing a new website for IB. Through this website, we aim to provide easy-to-use event-signup functions, to create a direct funding platform, and to highlight the incredible work done by volunteers.
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container1">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2FAbigail%20Li_Outreach%20Leader%20%40%20Redmond.jpg?alt=media&token=f6fa41cc-260f-4152-bd23-3f422f5c6349" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Abigail Li<br />
          Outreach of Redmond 
          </div>
          <div className="ourteam_selfintro">
          Hi! My name is Abigail Li, a freshman at Redmond High School. I started volunteering at International Buddy in 8th grade. Not only have I enjoyed getting to know fellow volunteers, but it has also been amazing getting to know the kids! With all of the kids having unique backgrounds, it's very interesting to learn about each and every one of them. My experiences at this organization have allowed for me to grow as a person, allowing for me to gain an understanding of how to work under pressure, along with in unique situations. I'm excited to be the Outreach Lead of Redmond, where I hope to "spread the wealth" about International Buddy, in hopes of growing our community through new volunteers and kids, in various communities, cities, and school districts. 
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container2">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2FNathan%20Guan%20-%20Outreach%20Leader%20%40%20Kirkland.jpg?alt=media&token=77dd7225-87f1-4521-aa7c-1aa8afc371f9" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Nathan Guan<br />
          Director of Tutoring & Career Training
          </div>
          <div className="ourteam_selfintro">
          I'm Nathan Guan, a Junior at Lake Washington High School and enjoys helping people with special needs while advocating for neurodiversity. Having ADHD, I understand many of the difficulties that others may experience and hopes to build an inclusive environment for everyone. My journey with IB started by playing basketball with kids but has expanded to creating opportunities and events. With focusing on online tutoring and career readiness training, I am excited to help the special needs community thrive.
          </div>
        </div>
      </div>
    
      <Line color="--green-color" width="100%" />
      <div className="ourteam_container1">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2FCelebes%20Zhang_Outreach%20of%20Snoqualmie.jpg?alt=media&token=9bbbdcdf-e307-4b68-8b76-698177c676cd" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Celena Zhang <br />
          Outreach of Snoqualmie
          </div>
          <div className="ourteam_selfintro">
          Hi, I'm Celena Zhang, a freshman at Mount Si High School for the 2023-24 school year. I joined International Buddy's in 2022 and since then have participated in many activities with the kids, including playground time, art, and reading. As outreach, I have successfully brought in other individuals interested in the organization. I look forward to helping more people connect with the resources they need while most importantly, having fun.
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container2">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2FAndrew%20Liu%20-%20Outreach%20of%20Mercer%20Island.jpg?alt=media&token=e03de0ad-5655-4639-b5e7-4557d99b4cd0" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Andrew Liu<br />
          Outreach of Mercer Island
          </div>
          <div className="ourteam_selfintro">
          Hello, my name is Andrew Liu, and I am a junior at Mercer Island High School for the 2023-2024 school year. I have participated in the Swimming Program of International Buddies in this past year, and gained memorable experiences while teaching/playing with the kids in the water. As outreach, I will spread awareness and grow the presence of International Buddies in Mercer Island Area.
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container1">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2FValerie%20Wang%20-%20tennis%20lead.jpg?alt=media&token=aca0309a-a48f-40d2-a5e2-a7531e822fa4" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Valerie Wang<br />
          Tennis Team Leader
          </div>
          <div className="ourteam_selfintro">
          Hi! My name is Valerie Wang and I am a Junior at International School Bellevue. I have been a part of International Buddy for about two years now and I have loved every second of it. I am so happy to be able to serve as the tennis leader. I have been playing tennis since I was 7 years old and I am actively training and competing in tournaments. Through my tennis skills, I hope to be able to reach out to the kids through the sport of tennis and help them learn and hopefully love the sport as much as I do. 
          </div>
        </div>
      </div>

    </div>
    </div>
      
  )
}

export default Ourteam20232024