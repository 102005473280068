import React from 'react'

const Nopage = () => {
  return (
    <div>
      <br /><br />
      <h2>The requested page does not exist!</h2>
      <br /><br />
    </div>
  )
}

export default Nopage